import { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import NavigationBar from "../ui/navigationBar";
import './Player.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faHeart, faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const Player = () => {

    const { video } = useParams();

    const [movie, setMovie] = useState(null)

    const [isVideo, setModType] = useState(null);

    const [showTitle, setShowTitle] = useState(true);

    useEffect(() => {

        const fetchMovies = async () => {

            const response = await fetch('https://sapi.grupoaml.org/public/track/' + video);

            const { data }: any = await response.json();

            setMovie(data);

            setShowTitle(data.title);

            if (data.type == 'video') {
                setModType(true);
            }

            console.log(data);

        }

        fetchMovies();

    }, []);



    async function sendRanking(arg0: number): Promise<void> {

        const response = await fetch(`https://sapi.grupoaml.org/public/ranking/${video}/${arg0}`);

        const { ok }: any = await response.json();

        if (ok == true) {
            toast.success('¡Gracias por tu contribución!', {
                position: 'top-right',
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
            });
        }
    }

    /**/

    const [volume, setVolume] = useState(1);



    return (
        <div className="text-gray-800 overflow-auto">

            <header>
                <NavigationBar />
            </header>

            <main className="h-screen bg-black">

                {movie && isVideo ? (

                    <div className="relative w-full flex items-center justify-center bg-black">
                        <video
                            className="top-1/2"
                            onPlaying={() => setShowTitle(false)}
                            onPause={() => setShowTitle(true)}
                            controls>
                            <source src={movie.location} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>

                        {showTitle && (

                            <h1 className="absolute flex flex-col top-1/2 left-1/2 transform -translate-x-1/2 text-2xl font-bold text-white">

                                {movie.title}

                                <button>
                                    <Link to="/">
                                        <FontAwesomeIcon icon={faChevronLeft} /> Regresar
                                    </Link>
                                </button>

                            </h1>

                        )}

                        <div className="absolute top-4 right-4 flex space-x-4">
                            <button className="p-2 bg-gray-200 rounded-full" onClick={() => sendRanking(1)}>
                                <FontAwesomeIcon icon={faThumbsUp} className="text-gray-700" />
                            </button>

                            <button className="p-2 bg-gray-200 rounded-full" onClick={() => sendRanking(2)}>
                                <FontAwesomeIcon icon={faHeart} className="text-gray-700" />
                            </button>

                            <button className="p-2 bg-gray-200 rounded-full" onClick={() => sendRanking(3)}>
                                <FontAwesomeIcon icon={faThumbsDown} className="text-gray-700" />
                            </button>
                        </div>

                    </div>

                ) : ([])}

                {movie && !isVideo ? (
                    
                    <div className="relative max-w-md top-1/4 p-4 mx-auto bg-white rounded-lg shadow-lg">
                    
                    
                    {/* Imagen */}
                    <img
                      src={movie.thumb}
                      alt="Audio"
                      className="w-full h-48 object-cover rounded-t-lg"
                    />
              
                    {/* Título */}
                    <h2 className="text-xl font-bold mt-4">{movie.title}</h2>
              
                    {/* Descripción */}
                    <p className="text-gray-600 mt-2">

                    </p>
              
                    {/* Reproductor de Audio */}
                    <div className="mt-4">
                      <audio controls className="w-full">
                        <source src={movie.location} type="audio/mp3" />
                        Your browser does not support the audio element.
                      </audio>
                    </div>

                    <button className="mt-4">
                        
                        <Link to="/">
                            <FontAwesomeIcon icon={faChevronLeft} /> Regresar
                        </Link>

                        <div className="absolute top-4 right-4 flex space-x-4">

                            <button className="p-2 bg-gray-200 rounded-full" onClick={() => sendRanking(1)}>
                                <FontAwesomeIcon icon={faThumbsUp} className="text-gray-700" />
                            </button>

                            <button className="p-2 bg-gray-200 rounded-full" onClick={() => sendRanking(2)}>
                                <FontAwesomeIcon icon={faHeart} className="text-gray-700" />
                            </button>

                            <button className="p-2 bg-gray-200 rounded-full" onClick={() => sendRanking(3)}>
                                <FontAwesomeIcon icon={faThumbsDown} className="text-gray-700" />
                            </button>

                        </div>

                    </button>

                  </div>

                ) : ([])};
            </main>

            <ToastContainer />

        </div>
    )

}

export default Player;