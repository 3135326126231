import React, { useEffect, useState } from 'react'
import NavigationBar from '../ui/navigationBar';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTvAlt } from '@fortawesome/free-solid-svg-icons';

const Categories = () => {

    const [genders, setGenders] = useState([])

    useEffect(() => {

        const fetchMovies = async () => {
            
            const response = await fetch('https://sapi.grupoaml.org/api/v2/genders');

            const { ok, data }: any = await response.json();

            console.log(38, data, ok);

            if(ok === true) {
                setGenders(data);
            }

        }

        fetchMovies();

    }, []);
    
    return (
        <div className="bg-gray-100 min-h-screen text-gray-800 overflow-auto">

            <header>
                <NavigationBar />
            </header>

            <main>

                <section className="py-4 pl-4 pr-4">
                    
                    <div className="container mx-auto">
                    
                        <h2 className="mb-4 text-gray-800 font-bold text-2xl">Categorias</h2>
                    
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 lg:grid-cols-6 gap-4">
                            {Array.isArray(genders) ?  genders && genders.map((movie, index) => (
                                
                                <Link key={index} className="movie-item" to={`/videos/${movie.id}`}>
                                    <button className="bg-blue-500 py-5 px-5 hover:bg-red-700 text-white font-medium w-full h-full rounded">
                                        
                                        {movie.name} 
                                        
                                        <p>
                                            <FontAwesomeIcon icon={faTvAlt} />  {movie.total}
                                        </p>

                                    </button>
                                </Link>

                            )) : ( <div> Nada por aqui :( </div>) }
                        </div>
                    </div>

                </section>

            </main>

        </div>
    )
}

export default Categories;